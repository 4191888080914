"use client"
import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto px-3 md:px-10 py-5">
            <h2 className='text-center font-semibold text-3xl pb-3 underline'>Privacy And Policy</h2>
            <div className="">
                <p className="mb-4">
                    By using our Platform and/or providing your information, you agree to the collection and use of the data you share on our Website in accordance with this Privacy Policy. This includes your consent to share your information as outlined in this Policy. Should we make any changes to our Privacy Policy, we will update this page to keep you informed about what information we collect, how we use it, and the circumstances under which we disclose it. If you do not agree with the terms outlined above, please refrain from using or accessing our Website.
                </p>

                <h2 className="text-xl font-semibold mt-6 mb-2">1. Collection of Personally Identifiable Information</h2>
                <p className="mb-2">We collect certain information about you to help us serve you better. The information collected includes:</p>

                <ul className="custom-list-style list-inside mb-4 px-[30px]">
                    <li>Name</li>
                    <li>Telephone Number</li>
                    <li>Email Address</li>
                    <li>Service Address</li>
                    <li>Other information about the service address provided by you</li>
                    <li>IP Address</li>
                    <li>Device Information</li>
                    <li>Network Information</li>
                    <li>College/Institution Details and Location</li>
                    <li>User-uploaded Photos and IDs</li>
                    <li>Demographic Information (e.g., postcode, preferences, interests)</li>
                    <li>Any other personal information you provide while booking a service or during customer surveys/offers.</li>
                </ul>

                <h3 className="font-semibold">Registration Information:</h3>
                <p className="mb-2">When creating a profile to use our services, you may be asked to provide:</p>

                <ul className="custom-list-style list-inside mb-4 px-[30px]">
                    <li>Name</li>
                    <li>Username</li>
                    <li>Password</li>
                    <li>Email Address</li>
                    <li>Specialty (selected from a drop-down menu if applicable)</li>
                    <li>Year of Graduation or Year of Joining Undergraduate Medical Program</li>
                    <li>College or University Name</li>
                    <li>Optionally, a profile picture</li>
                </ul>

                <h3 className="font-semibold">Social Media Registration:</h3>
                <p className="mb-4">If you register using Facebook, you grant us access to your profile information, including your friends list. Your use of Facebook is governed by Facebooks Terms & Conditions and Privacy Policy.</p>

                <h3 className="font-semibold">Data Storage:</h3>
                <p className="mb-4">Your username, country, and specialty are stored on an unencrypted server. Your password is cryptographically hashed. Collectively, this is referred to as your “Personal User Information.”</p>

                <h3 className="font-semibold">User Responsibilities:</h3>
                <ul className="custom-list-style list-inside mb-4 px-[30px]">
                    <li>Maintaining the confidentiality of your Personal User Information.</li>
                    <li>Not allowing others to use your Personal User Information to access our services.</li>
                    <li>Any damages resulting from activities that occur in your account.</li>
                    <li>Ensuring that the information you provide is correct and updated.</li>
                </ul>

                <h3 className="font-semibold">Security Notification:</h3>
                <p className="mb-4">Notify us immediately at
                    <a href={`mailto:hello@kaksya.in`} className="text-blue-500 hover:underline px-1">
                        hello@kaksya.in
                    </a>
                    of any unauthorized use of your Personal User Information.</p>


                <h3 className="font-semibold">Anonymous Browsing:</h3>
                <p className="mb-4">You may browse our website without providing personal information. We may track certain information based on your website behavior (e.g., referring URLs, browser information, IP address).</p>

                <h3 className="font-semibold">Cookies:</h3>
                <p className="mb-4">We use cookies to analyze web traffic, measure promotional effectiveness, and enhance user experience. You can decline cookies through your browser settings, but certain features may not be available.</p>

                <h2 className="text-xl font-semibold mt-6 mb-2">2. Use of Personal Information</h2>
                <p className="mb-2">The information we collect is used for various purposes:</p>

                <ul className="custom-list-style list-inside mb-4 px-[30px]">
                    <li>To find third-party service providers.</li>
                    <li>For internal record-keeping.</li>
                    <li>To improve our products and services.</li>
                    <li>To send promotional emails about new products, special offers, or other information we think you may find interesting.</li>
                    <li>To customize the website according to your interests.</li>
                </ul>

                <p className="mb-4">We may also use your personal information to resolve disputes, promote a safe service environment, and detect and prevent fraud.</p>

                <h2 className="text-xl font-semibold mt-6 mb-2">3. Sharing of Personal Information</h2>
                <p className="mb-2">We may share your information with:</p>

                <ul className="custom-list-style list-inside mb-4 px-[30px]">
                    <li>Payment service providers and regulatory authorities when required.</li>
                    <li>Law enforcement or legal authorities when necessary to comply with the law or protect our rights and those of others.</li>
                    <li>Third-party businesses if we undergo a merger or acquisition. The new entity will adhere to this privacy policy.</li>
                    <li>Advertisers with aggregate or anonymized information to help target their audience.</li>
                </ul>

                <p className="mb-4">If you subscribe to any plan, you may be required to upload a valid government-issued ID.</p>

                <h2 className="text-xl font-semibold mt-6 mb-2">4. Information Safety</h2>
                <p className="mb-4">All information is stored on secure servers protected by passwords and security measures to prevent unauthorized access.</p>

                <h2 className="text-xl font-semibold mt-6 mb-2">5. Choice/Opt-Out</h2>
                <p className="mb-4">You can opt-out of receiving promotional communications from us. To remove your contact information from our lists, please contact us at
                    <a href={`mailto:hello@kaksya.in`} className="text-blue-500 hover:underline px-1">
                        hello@kaksya.in
                    </a>.
                </p>

                <h2 className="text-xl font-semibold mt-6 mb-2">6. Contact Information</h2>
                <p className="mb-4 text-blue">For any questions or concerns regarding this privacy policy, please reach out to us at
                    <a href={`mailto:hello@kaksya.in`} className="text-blue-500 hover:underline px-1">
                        hello@kaksya.in
                    </a>
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy
